import React from 'react'
import "../styles/style.scss";
import { Link } from "gatsby";

function Mailer() {
    return (
        <div className="mailerSize">
            <div className="mailerMargin">
            
                {/* Banner Image */}
                <div className = "mailerBanner">
                
                    <div className="mailerBannerDetails">

                        <div><img src={require("../img/hp/mailer/OIC-logo.png")}/></div>

                        <div>
                            <h1>Digitally grow your <br/> Eyewear Buisness with <br /><span>OIC Technology</span></h1>
                        </div>

                    </div>
                
                </div> 

                {/* OIC Platform Associates */}

                <div className="mailerOiCPlat">
                    
                    <div>

                        <p>OIC platform associates Retailers with Brands, Distributors and Customers. OIC has diligently built
                        assorted essential apps to ease the business handling aspects that’s mandatory for the eyewear 
                        industry. That’s accessible on the App store. Use OIC to enjoy all our features and step-forward 
                        in your optical business.
                        </p>

                    </div>

                </div>

                {/* Glimpse Of Products */}

                <div className="mailerGlimpse">

                    <h2>Glimpse of our Products</h2>

                    <div className="mailerGlimpseProductsContainer">
                        
                        {/* 1 */}

                        <div className="mailerGlimpseProductsCard">

                            <img src={require("../img/hp/mailer/stylish-female-glasses-looking-happy.png")} />

                            <h2>Market Place</h2>

                            <p>Join the Marketplace Revolution. Today's buyers want more. Give them more 
                                advanced features with an OIC marketplace.
                            </p>

                            <div><Link to="/market-place/">Learn More</Link></div>

                        </div>

                        {/* 2 */}

                        <div className="mailerGlimpseProductsCard">

                            <img src={require("../img/hp/mailer/portrait-man-face-scann1.png")} />

                            <h2>Virtual Mirror</h2>

                            <p>Try Before You Buy. The Virtual Mirror Lets You Try On Any Pair Of Glasses 
                                From Your Comfort.
                            </p>

                            <div><Link to="/virtual-mirror/">Learn More</Link></div>

                        </div>

                        {/* 3 */}

                        <div className="mailerGlimpseProductsCard">

                            <img src={require("../img/hp/mailer/woman-with-mask-store-trying-glasses.png")} />

                            <h2>POS</h2>

                            <p>OIC POS Can Increase Efficiency, Improve Accuracy, Cut Operational Costs And
                                Manage Your Inventory.
                            </p>

                            <div><Link to="/pos/">Learn More</Link></div>

                        </div>

                        {/* 4 */}

                        <div className="mailerGlimpseProductsCard">

                            <img src={require("../img/hp/mailer/optometrist-advising-woman-choosing-glasses.png")} />

                            <h2>CRM</h2>

                            <p>Accelerate Sales, Make Smarter Inventory Management, And Build Better Customer
                                Relationships With OIC.
                            </p>

                            <div><Link to="/crm/">Learn More</Link></div>

                        </div>

                        {/* 5 */}

                        <div className="mailerGlimpseProductsCard">

                            <img src={require("../img/hp/mailer/lens-simulation-app.png")} />

                            <h2>Lens Simulation</h2>

                            <p>Lens Simulation Gives The Customer With The Thorough Glasses Details And 
                                Improves The Shopping Experience.
                            </p>

                            <div><Link to="/lens-simulation/">Learn More</Link></div>

                        </div>

                        {/* 6 */}

                        <div className="mailerGlimpseProductsCard">

                            <img src={require("../img/hp/mailer/website-builder.png")} />

                            <h2>Website Builder</h2>

                            <p>Make A Website In A Minute, Build Your Business, Conquer The World. Zero Code 
                                Or Design Skills Required.
                            </p>

                            <div><Link to="/website-building/">Learn More</Link></div>

                        </div>


                    </div>

                </div>

            </div>
            
            <div className="mailerFooter">

                <div className="mailerOiCLogo"><img src={require("../img/hp/mailer/OIC-Logo-white.png")} /></div>

                <div className="mailerOiCSocial">

                    <a href="https://www.facebook.com/OIC-105456311612773" target="_blank" rel="nofollow">
                        <img src={require("../img/hp/mailer/facebook.svg")} width="15" />
                    </a>

                    <a href="https://www.instagram.com/oicapps/" target="_blank" rel="nofollow">
                        <img src={require("../img/hp/mailer/instagram.svg")} width="15" />
                    </a>

                    <a href="https://www.linkedin.com/company/oicapps/" target="_blank" rel="nofollow">
                        <img src={require("../img/hp/mailer/linkedin.svg")} width="15" />
                    </a>

                    <a href="https://www.twitter.com/OIC02395398" target="_blank" rel="nofollow">
                        <img src={require("../img/hp/mailer/twitter.svg")} width="15" />
                    </a>

                </div>

                <div className="mailerOiCHelp">

                    <Link to="">Help Centre</Link>

                    <Link to="">Contact</Link>

                    <Link to="">Unsubscribe</Link>

                </div>

            </div>
        </div>
    )
}

export default Mailer
